import { useState, useEffect } from "react";
import "./Admin.scss";

import { isAdmin, successNotification } from "../../utils/Common";
import { getAllUsers, getCourtList } from "../../services/api";
import { Toaster } from "react-hot-toast";

import AdminNavbar from "../../components/adminNavbar/AdminNavbar";
import AdminUsersTable from "../../components/adminUsersTable/AdminUsersTable";
import AdminCourtsTable from "../../components/adminCourtsTable/AdminCourtsTable";
import UserModal from "../../components/adminUserModal/AdminUserModal";
import AdminCourtModal from "../../components/adminCourtModal/AdminCourtModal";

function Admin(props) {
  // if not admin redirect to bookings
  if (!isAdmin()) {
    props.history.push("/bookings");
  }

  // set states
  const [showUserModal, setShowUserModal] = useState(false);
  const [showCourtModal, setShowCourtModal] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [currentCourt, setCurrentCourt] = useState({});
  const [usersList, setUsersList] = useState([]);
  const [courtsList, setCourtsList] = useState([]);
  const [activeTab, setActiveTab] = useState("Utenti");

  // set tabs list
  const tabs = ["Utenti", "Campi"];

  function onOpenUserModal(user) {
    setCurrentUser(user);
    setShowUserModal(true);
  }

  function onCloseUserModal() {
    setCurrentUser({});
    setShowUserModal(false);
    loadUsers();
  }

  function onOpenCourtModal(court) {
    setCurrentCourt(court);
    setShowCourtModal(true);
  }

  function onCloseCourtModal() {
    setCurrentCourt({});
    setShowCourtModal(false);
    loadCourts();
  }

  function notify(message) {
    successNotification(message);
  }

  const handleChangeTab = (e) => {
    setActiveTab(e);
  };

  async function loadUsers() {
    try {
      // load all users
      let { data } = await getAllUsers();
      setUsersList(data);
    } catch (err) {
      console.log(err);
    }
  }

  async function loadCourts() {
    try {
      // load all users
      let { data } = await getCourtList();
      setCourtsList(data.courtList);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    loadUsers();
    loadCourts();
  }, []);

  return (
    <div className="container-fluid px-0 admin-page">
      <AdminNavbar
        handleChangeTab={handleChangeTab}
        activeTab={activeTab}
        tabs={tabs}
      ></AdminNavbar>
      <div className="mt-2 px-4">
        <div className="row mb-4">
          {activeTab === "Utenti" ? (
            <>
              <h3 className="ml-3">Gestione Utenti</h3>
              <button
                className="btn btn-sm add-button ml-auto mr-3"
                onClick={() => onOpenUserModal({})}
              >
                Nuovo utente
              </button>
            </>
          ) : (
            <>
              <h3 className="ml-3">Gestione Campi</h3>
              <button
                className="btn btn-sm add-button ml-auto mr-3"
                onClick={() => onOpenCourtModal({})}
              >
                Nuovo campo
              </button>
            </>
          )}
        </div>
        {activeTab === "Utenti" ? (
          <AdminUsersTable
            loadUsers={loadUsers}
            users={usersList}
            onOpenUserModal={onOpenUserModal}
            onNotify={notify}
          />
        ) : (
          <AdminCourtsTable
            loadCourts={loadCourts}
            courts={courtsList}
            onOpenCourtModal={onOpenCourtModal}
            onNotify={notify}
          />
        )}
      </div>
      {showUserModal && (
        <UserModal
          user={currentUser}
          onClose={onCloseUserModal}
          onNotify={notify}
        />
      )}
      {showCourtModal && (
        <AdminCourtModal
          court={currentCourt}
          onClose={onCloseCourtModal}
          onNotify={notify}
        />
      )}
      <Toaster />
    </div>
  );
}

export default Admin;
