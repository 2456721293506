import "./DesktopCourt.scss";

import { isAdmin } from "../../../utils/Common";

import Slot from "../slot/Slot";

function DesktopCourt(props) {
  return (
    <div className="col desktop-court d-none d-lg-block">
      <div className="row justify-content-center align-items-center top-name court-name">
        <span>{props.court.name}</span>
        {isAdmin() && (
          <button
            className="disable-button ml-4"
            type="button"
            name="disable"
            onClick={() => props.handleCourtDisabled(props.court)}
          >
            {props.court.isDisabled ? "Sblocca" : "Blocca"}
          </button>
        )}
        {props.court.isDisabled && (
          <span className="ml-4 text-muted">Bloccato</span>
        )}
      </div>
      {props.court.slots.map((slot, i) => {
        return (
          <Slot
            isCourtNotOpen={props.court.isNotOpen}
            isCourtDisabled={props.court.isDisabled}
            slot={slot}
            key={"desktopSlot_" + i}
            openModal={props.openModal}
          />
        );
      })}
      <div className="row justify-content-center align-items-center bottom-name court-name">
        <span>{props.court.name}</span>
        {props.court.isDisabled && (
          <span className="ml-4 text-muted">Bloccato</span>
        )}
      </div>
    </div>
  );
}

export default DesktopCourt;
