import { useState, useEffect } from "react";
import "./BookingModal.scss";

import Select from "react-select";
import { getActivePlayers, getAllCoaches } from "../../../services/api";
import {
  getUser,
  isAdmin,
  isCoach,
  useFormInput,
  formatDate,
  getWeekday,
} from "../../../utils/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function BookingModal(props) {
  // get user info
  const user = getUser();

  // set event types options
  const eventTypes = isAdmin()
    ? [
        { value: "friendly", label: "Amichevole" },
        { value: "tournament", label: "Torneo" },
        { value: "social", label: "Torneo Sociale" },
        { value: "coach", label: "Maestro" },
      ]
    : [
        { value: "friendly", label: "Amichevole" },
        { value: "coach", label: "Maestro" },
      ];

  // players inputs
  const unsignedPLayer1 = useFormInput("");
  const unsignedPLayer2 = useFormInput("");
  const unsignedPLayer3 = useFormInput("");
  const unsignedPLayer4 = useFormInput("");
  const [player1, setPlayer1] = useState(user.playerId);
  const [player2, setPlayer2] = useState();
  const [player3, setPlayer3] = useState();
  const [player4, setPlayer4] = useState();

  // booking inputs
  const endTime = useFormInput("");
  const [matchType, setMatchType] = useState("single");
  const [eventType, setEventType] = useState("friendly");

  // select lists
  const [playerList, setPlayerList] = useState();
  const [coachList, setCoachList] = useState();

  function onMatchTypeChange(e) {
    if (e.target.value === "single") {
      setPlayer3(null);
      setPlayer4(null);
    }
    setMatchType(e.target.value);
  }

  function onPlayerChange(e, index) {
    // set selected player
    switch (index) {
      case 1:
        setPlayer1(e.value);

        break;

      case 2:
        setPlayer2(e.value);

        break;

      case 3:
        setPlayer3(e.value);

        break;
      case 4:
        setPlayer4(e.value);

        break;

      default:
        break;
    }
  }

  function onEventTypeChange(e) {
    // set selected event type
    if (e.value === "tournament" || e.value === "social") {
      setPlayer1(null);
    }
    if (e.value === "friendly" && !isAdmin()) {
      // if friendly and not admin set player1 as user
      setPlayer1(user.playerId);
    }
    if (e.value !== "friendly") {
      setPlayer2(null);
      setPlayer3(null);
      setPlayer4(null);
    }
    if (e.value === "coach" && !isAdmin()) {
      // if coach and not admin set player1 as user
      setPlayer1(user.playerId);
      setMatchType("coach");
    } else if (e.value === "coach") {
      setPlayer1(null);
      setMatchType("coach");
    } else {
      setMatchType("single");
    }
    setEventType(e.value);
  }

  function getEventTypeTranslation() {
    // set selected player
    switch (props.slot.eventType) {
      case "tournament":
        return "Torneo FIT";

      case "social":
        return "Torneo Sociale";

      case "coach":
        return "Maestro";

      case "friendly":
        return "Amichevole";

      default:
        return "Amichevole";
    }
  }

  function onBooking() {
    let unsignedPlayers = [];
    // set tournament or unsigned players
    if (isTournament()) {
      unsignedPlayers.push(unsignedPLayer1.value, unsignedPLayer2.value);
      if (matchType === "double") {
        unsignedPlayers.push(unsignedPLayer3.value, unsignedPLayer4.value);
      }
    } else {
      // set guest names to unsigned players
      let playerList = [player1, player2, player3, player4];
      let guestList = [
        unsignedPLayer1.value,
        unsignedPLayer2.value,
        unsignedPLayer3.value,
        unsignedPLayer4.value,
      ];
      for (let i = 0; i < 4; i++) {
        if (playerList[i] === "ospite") {
          unsignedPlayers.push(guestList[i]);
        }
      }
    }

    props.onBooking({
      matchType,
      player1,
      player2,
      player3,
      player4,
      eventType,
      unsignedPlayers,
      endTime,
    });
  }

  function isTournament() {
    return eventType === "tournament" || eventType === "social";
  }

  function hasGuests() {
    return (
      player1 === "ospite" ||
      player2 === "ospite" ||
      player3 === "ospite" ||
      player4 === "ospite"
    );
  }

  useEffect(() => {
    async function loadPlayers() {
      try {
        // load all players
        let { data } = await getActivePlayers();

        // set players for select
        const playerList = [];
        data.forEach((player) => {
          if (
            player.playerId !== player1 &&
            player.playerId !== player2 &&
            player.playerId !== player3 &&
            player.playerId !== player4 &&
            (isAdmin() || player.playerId !== user.playerId)
          ) {
            playerList.push({
              value: player.playerId,
              label:
                player.lastName.toUpperCase() +
                " " +
                player.firstName.toUpperCase(),
            });
          }
        });

        // add guests
        playerList.push({
          value: "ospite",
          label: "OSPITE",
        });

        setPlayerList(playerList);
      } catch (err) {
        console.log(err);
      }
    }

    async function loadCoaches() {
      try {
        // load all players
        let { data } = await getAllCoaches();

        // set coaches for select
        const coachList = [];
        data.forEach((coach) => {
          coachList.push({
            value: coach.playerId,
            label: coach.lastName + " " + coach.firstName,
          });
        });

        setCoachList(coachList);
      } catch (err) {
        console.log(err);
      }
    }

    loadPlayers();
    loadCoaches();
  }, [player1, player2, player3, player4, user.playerId]);

  return (
    <div className="booking-modal-container">
      <div className="row justify-content-center align-items-center h-100">
        <div className="card">
          <div className="text-right">
            <FontAwesomeIcon
              icon={faTimes}
              className="close-icon mr-4 mt-4"
              onClick={props.onClose}
            />
          </div>
          <form className="box">
            <h1>Prenotazione</h1>
            <div className="row pb-2">
              <div className="col-12">
                <span>{getWeekday(props.slot.date)}</span>
                <span className="mx-2">
                  {formatDate(props.slot.date, "/", "it")}
                </span>
                <span className="time-section px-2">
                  <span>{props.slot.startTime}</span>
                  {!props.slot.isEmpty && (
                    <>
                      <span className="mx-2"> - </span>
                      <span>{props.slot.endTime}</span>
                    </>
                  )}
                </span>
                <span className="mx-2">{props.slot.courtName}</span>
              </div>
            </div>
            {!props.isReadonly && eventType !== "coach" && (
              <>
                <div className="row form-section pb-2 pt-4 m-0">
                  <div className="col-12 row justify-content-around">
                    <span className="col-12 col-sm">Tipo di match:</span>
                    <div className="col-12 col-sm">
                      <label>
                        <input
                          type="radio"
                          value="single"
                          checked={matchType === "single"}
                          onChange={onMatchTypeChange}
                        />
                        Singolo
                      </label>
                    </div>
                    <div className="col-12 col-sm">
                      <label>
                        <input
                          type="radio"
                          value="double"
                          checked={matchType === "double"}
                          onChange={onMatchTypeChange}
                        />
                        Doppio
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!props.isReadonly && (isTournament() || eventType === "coach") && (
              <>
                <div className="row form-section py-2">
                  <div className="col-12 row align-items-center">
                    <span className="col">Ora fine:</span>
                    <input
                      className="form-control col"
                      type="text"
                      {...endTime}
                      autoComplete="new-end-time"
                      placeholder="00:00"
                    />
                  </div>
                </div>
              </>
            )}
            {eventType !== "coach" && (
              <div className="form-section mb-4">
                {!props.isReadonly && !isTournament() && (
                  <>
                    <span>Seleziona i giocatori:</span>
                    <div className="row pt-2">
                      {isAdmin() && (
                        <div className="col-12 col-sm-6">
                          <Select
                            options={playerList}
                            onChange={(event) => onPlayerChange(event, 1)}
                          />
                        </div>
                      )}
                      {!isAdmin() && (
                        <div className="col-12 col-sm-6">
                          {user.lastName} {user.firstName}
                        </div>
                      )}
                      <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                        <Select
                          options={playerList}
                          onChange={(event) => onPlayerChange(event, 2)}
                        />
                      </div>
                    </div>
                    {matchType === "double" && (
                      <div className="row pt-4">
                        <div className="col-12 col-sm-6">
                          <Select
                            options={playerList}
                            onChange={(event) => onPlayerChange(event, 3)}
                          />
                        </div>
                        <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                          <Select
                            options={playerList}
                            onChange={(event) => onPlayerChange(event, 4)}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                {!props.isReadonly && isTournament() && (
                  <>
                    <div className="row pt-4">
                      <div className="col-12 col-sm-6">
                        <input
                          className="form-control"
                          type="text"
                          {...unsignedPLayer1}
                          autoComplete="new-name"
                          placeholder="Giocatore"
                        />
                      </div>
                      <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                        <input
                          className="form-control"
                          type="text"
                          {...unsignedPLayer2}
                          autoComplete="new-name"
                          placeholder="Giocatore"
                        />
                      </div>
                    </div>
                    {matchType === "double" && (
                      <div className="row pt-4">
                        <div className="col-12 col-sm-6">
                          <input
                            className="form-control"
                            type="text"
                            {...unsignedPLayer3}
                            autoComplete="new-name"
                            placeholder="Giocatore"
                          />
                        </div>
                        <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                          <input
                            className="form-control"
                            type="text"
                            {...unsignedPLayer4}
                            autoComplete="new-name"
                            placeholder="Giocatore"
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                {props.isReadonly && (
                  <>
                    <span>Giocatori:</span>
                    <div className="row pt-2">
                      {props.slot.players.map((player, i) => {
                        return (
                          <div key={"player_" + i} className="col-12 col-sm-6">
                            {player.lastName} {player.firstName}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            )}
            {hasGuests() && (
              <>
                <div className="row form-section py-2">
                  <span className="col-12">Inserisci i nomi degli ospiti:</span>
                  {player1 === "ospite" && (
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        className="form-control"
                        type="text"
                        {...unsignedPLayer1}
                        autoComplete="new-name"
                        placeholder="Ospite"
                      />
                    </div>
                  )}
                  {player2 === "ospite" && (
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        className="form-control"
                        type="text"
                        {...unsignedPLayer2}
                        autoComplete="new-name"
                        placeholder="Ospite"
                      />
                    </div>
                  )}
                  {player3 === "ospite" && (
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        className="form-control"
                        type="text"
                        {...unsignedPLayer3}
                        autoComplete="new-name"
                        placeholder="Ospite"
                      />
                    </div>
                  )}
                  {player4 === "ospite" && (
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        className="form-control"
                        type="text"
                        {...unsignedPLayer4}
                        autoComplete="new-name"
                        placeholder="Ospite"
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            {!props.isReadonly && eventType === "coach" && isAdmin() && (
              <div className="form-section mb-4">
                <span>Seleziona il maestro:</span>
                <div className="row pt-2 justify-content-center">
                  <div className="col-12 col-sm-6">
                    <Select
                      options={coachList}
                      onChange={(event) => onPlayerChange(event, 1)}
                    />
                  </div>
                </div>
              </div>
            )}
            {!props.isReadonly && (isAdmin() || isCoach()) && (
              <>
                <div className="form-section pt-2">
                  <span>Tipo di evento:</span>
                  <div className="row pt-2 justify-content-center">
                    <div className="col-12 col-sm-6 match-select">
                      <Select
                        menuPlacement="auto"
                        options={eventTypes}
                        onChange={(event) => onEventTypeChange(event)}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {props.isReadonly && (
              <>
                <div className="form-section pt-2">
                  <span>Tipo di match:</span>
                  <span className="ml-2">{getEventTypeTranslation()}</span>
                </div>
              </>
            )}
            {props.error && (
              <>
                <small style={{ color: "red" }}>{props.error}</small>
              </>
            )}
            {!props.isReadonly ? (
              <button
                className="submit-button mt-4"
                type="button"
                name="submit"
                onClick={onBooking}
              >
                Aggiungi prenotazione
              </button>
            ) : (
              <button
                className="delete-button mt-4"
                type="button"
                name="delete"
                onClick={props.onDeleteBooking}
              >
                Cancella prenotazione
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default BookingModal;
