import { useState } from "react";
import "./MobileHeader.scss";

import { NavLink } from "react-router-dom";
import { getUser, removeUserSession, isAdmin } from "../../utils/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes,
  faCog,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/logo_transp.png";

function MobileHeader(props) {
  // get user info
  const user = getUser();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  // handle click event of logout button
  const handleLogout = () => {
    removeUserSession();
    props.history.push("/login");
  };

  return (
    <div>
      <div className="app-header row align-items-center m-0">
        <div className="col-6 app-logo text-left py-1">
          <img src={logo} className="logo" width="100" height="50" alt="logo" />
        </div>
        <div className="col text-right">
          <button className="btn btn-text" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
      </div>
      <nav className={"off-canvas" + (isMenuOpen ? " active" : "")}>
        <div className="overlay" onClick={toggleMenu}></div>
        <div className="close-button" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faTimes} className="close-icon mt-4" />
        </div>
        <div className="sidebar">
          <div className="app-logo text-center">
            <div className="row justify-content-center">
              <img src={logo} width="100" height="50" alt="logo" />
            </div>
            <div className="row justify-content-center name">
              <span>TC Valpellice</span>
            </div>
            <div className="mt-2">
              <FontAwesomeIcon icon={faUserCircle} />
            </div>
            <div>
              <p className="user-info-section">
                {user.lastName} {user.firstName}
              </p>
            </div>
            {/* <div>
              <span className="user-info-section">gettoni: <b>{user.credits}</b></span>
            </div> */}
          </div>
          <div className="sidebar-links">
            <NavLink
              className="nav-link px-0 py-1"
              activeClassName="active"
              to="/bookings"
              onClick={toggleMenu}
            >
              prenotazioni
            </NavLink>
            {isAdmin() && (
              <NavLink
                className="nav-link px-0 py-1"
                activeClassName="active"
                to="/admin"
                onClick={toggleMenu}
              >
                admin
              </NavLink>
            )}
            <NavLink
              className="nav-link px-0 py-1"
              activeClassName="active"
              to="/profile"
              onClick={toggleMenu}
            >
              <FontAwesomeIcon icon={faCog} />
              <span className="ml-2">impostazioni</span>
            </NavLink>
            <div className="nav-link px-0 py-4">
              <button
                className="btn btn-outline logout-button btn-sm"
                onClick={handleLogout}
                type="button"
              >
                <span>Logout</span>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default MobileHeader;
