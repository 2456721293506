import { useState } from "react";
import "./Login.scss";

import { setUserSession, useFormInput } from "../../utils/Common";
import { signin } from "../../services/api";
import logo from "../../../assets/logo_transp.png";

function Login(props) {
  const nickname = useFormInput("");
  const password = useFormInput("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // handle button click of login form
  const handleLogin = (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    // try to signin and set user in session by form data
    signin({ nickname: nickname.value, password: password.value })
      .then((response) => {
        setLoading(false);
        setUserSession(response.data.token, response.data.user);
        props.history.push("/bookings");
      })
      .catch((error) => {
        setLoading(false);
        setError(error.response.data.message);
      });
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  return (
    <div className="login-container">
      <div className="row">
        <div className="col-lg-6 col-xl-4">
          <form className="box min-vh-100">
            <div className="d-flex flex-column">
              <div className="mx-auto mb-lg-5 logo">
                <img width="100%" src={logo} alt="logo" />
              </div>
              <h1>Login</h1>
              <input
                className="form-control"
                type="text"
                {...nickname}
                autoComplete="new-password"
                placeholder="Nickname"
              />
              <input
                className="form-control"
                type="password"
                {...password}
                autoComplete="new-password"
                placeholder="password"
              />
              {error && (
                <>
                  <small style={{ color: "red" }}>{error}</small>
                </>
              )}
              <button
                className="login-button"
                type="submit"
                name="submit"
                onClick={handleLogin}
                onKeyPress={handleKeypress}
                disabled={loading}
              >
                {loading ? "Loading..." : "Login"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
