import { useState } from "react";
import "./AdminUsersTable.scss";

import {
  deleteUserRole,
  deleteUser,
  deletePlayer,
  addCredits,
} from "../../services/api";
import { adminResetPassword } from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserEdit,
  faTrashAlt,
  faUserLock,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";

import ConfirmModal from "../confirmModal/ConfirmModal";
import PasswordResetModal from "./passwordResetModal/PasswordResetModal";
import AddCoinsModal from "./addCoinsModal/AddCoinsModal";

function AdminUsersTable(props) {
  const [deleteMessage, setDeleteMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [showAddCoinsModal, setShowAddCoinsModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [error, setError] = useState(null);

  function onOpenConfirmModal(user) {
    setDeleteMessage(
      "Sicuro di voler eliminare l'utente: " +
        user.firstName +
        " " +
        user.lastName +
        "?"
    );
    setSelectedUser(user);
    setShowConfirmModal(true);
  }

  function onOpenPasswordResetModal(user) {
    setSelectedUser(user);
    setShowPasswordResetModal(true);
  }

  function onOpenAddCoinsModal(user) {
    setSelectedUser(user);
    setShowAddCoinsModal(true);
  }

  function onCloseModal() {
    setError(null);
    setShowConfirmModal(false);
    setShowPasswordResetModal(false);
    setShowAddCoinsModal(false);
    props.loadUsers();
  }

  function deleteSelectedUser() {
    deleteUserRole(selectedUser.id)
      .then((res) => {
        deleteUser(selectedUser.id).then((res) => {
          deletePlayer(selectedUser.playerId).then((res) => {
            setError(null);
            setShowConfirmModal(false);
            props.onNotify("Utente eliminato correttamente.");
            props.loadUsers();
          });
        });
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  }

  function onResetPassword(newPassword) {
    // set new password
    const passwordParams = { newPassword: newPassword };

    // try to change user password on db
    adminResetPassword(selectedUser.id, passwordParams)
      .then(() => {
        setError(null);
        props.onNotify("password utente resettata correttamente");
        onCloseModal();
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  }

  function onAddCredits(creditsToAdd) {
    // set credits to add
    const creditsParams = { creditsToAdd: creditsToAdd };

    // try to add credits to user on db
    addCredits(selectedUser.id, creditsParams)
      .then(() => {
        setError(null);
        props.onNotify("Gettoni aggiunti correttamente");
        onCloseModal();
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  }

  return (
    <div>
      <table className="table table-striped table-hover table-bordered table-responsive-md users-table">
        <thead>
          <tr>
            <th scope="col">Cognome</th>
            <th scope="col">Nome</th>
            <th scope="col">Nickname</th>
            <th scope="col">Abilitato</th>
            <th scope="col">Gettoni</th>
            <th scope="col">Agonista</th>
            <th scope="col">Classifica</th>
            <th scope="col">Ruolo</th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {props.users.map((user, i) => {
            return (
              <tr key={"user_" + i}>
                <td>{user.lastName}</td>
                <td>{user.firstName}</td>
                <td>{user.nickname}</td>
                <td>{user.isEnabled ? "si" : "no"}</td>
                <td>{user.credits}</td>
                <td>{user.isAgonistic ? "si" : "no"}</td>
                <td>{user.rank || "N.D."}</td>
                <td>
                  {user.role === "ADMIN"
                    ? "Admin"
                    : user.role === "COACH"
                    ? "Maestro"
                    : "Giocatore"}
                </td>
                <td>
                  <button
                    className="btn btn-text btn-sm"
                    onClick={() => props.onOpenUserModal(user)}
                  >
                    <FontAwesomeIcon icon={faUserEdit} />
                  </button>
                  <button
                    className="btn btn-text btn-sm"
                    onClick={() => onOpenAddCoinsModal(user)}
                  >
                    <FontAwesomeIcon icon={faCoins} />
                  </button>
                  <button
                    className="btn btn-text btn-sm"
                    onClick={() => onOpenPasswordResetModal(user)}
                  >
                    <FontAwesomeIcon icon={faUserLock} />
                  </button>
                  <button
                    className="btn btn-text btn-sm"
                    onClick={() => onOpenConfirmModal(user)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {showConfirmModal && (
        <ConfirmModal
          error={error}
          title="Elimina Utente"
          message={deleteMessage}
          onClose={onCloseModal}
          onSubmit={deleteSelectedUser}
        />
      )}
      {showPasswordResetModal && (
        <PasswordResetModal
          error={error}
          onClose={onCloseModal}
          user={selectedUser}
          onResetPassword={onResetPassword}
        />
      )}
      {showAddCoinsModal && (
        <AddCoinsModal
          error={error}
          onClose={onCloseModal}
          user={selectedUser}
          onAddCredits={onAddCredits}
        />
      )}
    </div>
  );
}

export default AdminUsersTable;
