import "./MobileCourt.scss";

import { isAdmin } from "../../../utils/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useSwipeable } from "react-swipeable";

import Slot from "../slot/Slot";

function MobileCourt(props) {
  // listen for swipe events
  const handlers = useSwipeable({
    onSwiped: (eventData) => handleSwipe(eventData),
  });

  // change court on swipe left or right
  function handleSwipe(eventData) {
    if (eventData.deltaY < 50 && eventData.deltaY > -50) {
      switch (eventData.dir) {
        case "Left":
          props.handleShownCourt(1);

          break;

        case "Right":
          props.handleShownCourt(-1);

          break;

        default:
          break;
      }
    }
  }

  return (
    <div className="col mobile-court d-lg-none" {...handlers}>
      <div className="row justify-content-center align-items-center top-court court-name">
        <button
          className="btn btn-text"
          onClick={() => props.handleShownCourt(-1)}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <span className="mx-2">{props.court.name}</span>
        <button
          className="btn btn-text"
          onClick={() => props.handleShownCourt(1)}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
        {isAdmin() && (
          <button
            className="disable-button ml-4"
            type="button"
            name="disable"
            onClick={() => props.handleCourtDisabled(props.court)}
          >
            {props.court.isDisabled ? "Sblocca" : "Blocca"}
          </button>
        )}
        {props.court.isDisabled && (
          <span className="ml-4 text-muted">Bloccato</span>
        )}
      </div>
      {props.court.slots.map((slot, i) => {
        return (
          <Slot
            isCourtNotOpen={props.court.isNotOpen}
            isCourtDisabled={props.court.isDisabled}
            slot={slot}
            key={"mobileSlot_" + i}
            openModal={props.openModal}
          />
        );
      })}
      <div className="row justify-content-center align-items-center bottom-court court-name">
        <button
          className="btn btn-text"
          onClick={() => props.handleShownCourt(-1)}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <span className="mx-2">{props.court.name}</span>
        <button
          className="btn btn-text"
          onClick={() => props.handleShownCourt(1)}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
        {props.court.isDisabled && (
          <span className="ml-4 text-muted">Bloccato</span>
        )}
      </div>
    </div>
  );
}

export default MobileCourt;
