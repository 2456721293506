import { http } from "./axios";

/* users */
export const signin = (signinParams, httpConfig = {}) => {
  const url = `/api/users/signin`;
  return http.post(url, signinParams, httpConfig);
};

export const getPlayer = (id, httpConfig = {}) => {
  const url = `/api/users/getPlayer/${id}`;
  return http.get(url, httpConfig);
};

export const verifyToken = (token, httpConfig = {}) => {
  const url = `/api/users/verifyToken`;
  return http.post(url, token, httpConfig);
};

export const getActivePlayers = (httpConfig = {}) => {
  const url = `/api/users/getActivePlayers`;
  return http.get(url, httpConfig);
};

export const getAllUsers = (httpConfig = {}) => {
  const url = `/api/users/getAllUsers`;
  return http.get(url, httpConfig);
};

export const getAllCoaches = (httpConfig = {}) => {
  const url = `/api/users/getAllCoaches`;
  return http.get(url, httpConfig);
};

export const getAvailableRoles = (httpConfig = {}) => {
  const url = `/api/users/getAvailableRoles`;
  return http.get(url, httpConfig);
};

export const createUser = (userParams, httpConfig = {}) => {
  const url = `/api/users/create`;
  return http.post(url, userParams, httpConfig);
};

export const deleteUserRole = (id, httpConfig = {}) => {
  const url = `/api/users/deleteUserRole/${id}`;
  return http.delete(url, httpConfig);
};

export const deleteUser = (id, httpConfig = {}) => {
  const url = `/api/users/deleteUser/${id}`;
  return http.delete(url, httpConfig);
};

export const deletePlayer = (id, httpConfig = {}) => {
  const url = `/api/users/deletePlayer/${id}`;
  return http.delete(url, httpConfig);
};

export const changeUserInfo = (id, userParams, httpConfig = {}) => {
  const url = `/api/users/changeUserInfo/${id}`;
  return http.put(url, userParams, httpConfig);
};

export const resetSelfPassword = (id, passwordParams, httpConfig = {}) => {
  const url = `/api/users/resetSelfPassword/${id}`;
  return http.put(url, passwordParams, httpConfig);
};

export const adminResetPassword = (id, passwordParams, httpConfig = {}) => {
  const url = `/api/users/adminResetPassword/${id}`;
  return http.put(url, passwordParams, httpConfig);
};

export const addCredits = (id, creditsParams, httpConfig = {}) => {
  const url = `/api/users/addCredits/${id}`;
  return http.put(url, creditsParams, httpConfig);
};

/* courts */
export const getCourtList = (httpConfig = {}) => {
  const url = `/api/courts/getAll`;
  return http.get(url, httpConfig);
};

export const createCourt = (courtParams, httpConfig = {}) => {
  const url = `/api/courts/create`;
  return http.post(url, courtParams, httpConfig);
};

export const deleteCourt = (id, httpConfig = {}) => {
  const url = `/api/courts/${id}`;
  return http.delete(url, httpConfig);
};

export const changeCourtInfo = (id, courtParams, httpConfig = {}) => {
  const url = `/api/courts/changeCourtInfo/${id}`;
  return http.put(url, courtParams, httpConfig);
};

/* bookings */
export const getBookingList = (currentDate, httpConfig = {}) => {
  const url = `/api/bookings/getList`;
  return http.post(url, currentDate, httpConfig);
};

export const createBooking = (bookingParams, httpConfig = {}) => {
  const url = `/api/bookings/create`;
  return http.post(url, bookingParams, httpConfig);
};

export const deleteBooking = (bookingParams, httpConfig = {}) => {
  const url = `/api/bookings/delete`;
  return http.post(url, bookingParams, httpConfig);
};
