import "./AddCoinsModal.scss";

import { useFormInput } from "../../../utils/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function AddCoinsModal(props) {
  // credits input
  const newCredits = useFormInput(0);

  return (
    <div className="add-credits-modal-container">
      <div className="row justify-content-center align-items-center h-100">
        <div className="card p-4 mt-4">
          <div className="text-right">
            <FontAwesomeIcon
              icon={faTimes}
              className="close-icon"
              onClick={props.onClose}
            />
          </div>
          <h3>Aggiungi gettoni</h3>
          <p>
            Seleziona la quantità di gettoni da aggiungere all'utente{" "}
            <b>{props.user.firstName + " " + props.user.lastName}</b>
          </p>
          <div className="row">
            <div className="col-12 col-md">
              <span>Gettoni:</span>
              <input
                className="form-control"
                type="number"
                {...newCredits}
                autoComplete="new-credits"
                placeholder="Gettoni"
              />
            </div>
          </div>
          {props.error && (
            <div className="mt-2 d-flex justify-content-center">
              <small style={{ color: "red" }}>{props.error}</small>
            </div>
          )}
          <button
            className="submit-button mt-4"
            type="button"
            name="submit"
            onClick={() => props.onAddCredits(newCredits.value)}
          >
            Aggiungi
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddCoinsModal;
