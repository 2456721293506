import axios from "axios";
import { getToken } from "../utils/Common";

export const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

addHeaders(http);

function addHeaders(httpWithToken) {
  http.interceptors.request.use((cfg) => {
    cfg.headers = cfg.headers || {};
    var token = getToken();
    if (token) {
      cfg.headers.Authorization = token;
    }

    return cfg;
  });
}
