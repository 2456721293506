import "./PasswordResetModal.scss";

import { useFormInput } from "../../../utils/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function PasswordResetModal(props) {
  // password input
  const newPassword = useFormInput("");

  return (
    <div className="password-reset-modal-container">
      <div className="row justify-content-center align-items-center h-100">
        <div className="card p-4 mt-4">
          <div className="text-right">
            <FontAwesomeIcon
              icon={faTimes}
              className="close-icon"
              onClick={props.onClose}
            />
          </div>
          <h3>Reset password</h3>
          <p>
            Seleziona una nuova password per l'utente{" "}
            {props.user.firstName + " " + props.user.lastName}
          </p>
          <div className="row">
            <div className="col-12 col-md">
              <span>Nuova Password:</span>
              <input
                className="form-control"
                type="password"
                {...newPassword}
                autoComplete="new-password"
                placeholder="Password"
              />
            </div>
          </div>
          {props.error && (
            <div className="mt-2 d-flex justify-content-center">
              <small style={{ color: "red" }}>{props.error}</small>
            </div>
          )}
          <button
            className="submit-button mt-4"
            type="button"
            name="submit"
            onClick={() => props.onResetPassword(newPassword.value)}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetModal;
