import "./AdminNavbar.scss";

function AdminNavbar(props) {
  return (
    <div className="admin-tabs">
      <ul className="nav nav-tabs">
        {props.tabs.map((tab, i) => {
          return (
            <li className="nav-item" key={"tab_" + i}>
              <button
                className={
                  "nav-link" + (props.activeTab === tab ? " active" : "")
                }
                onClick={() => props.handleChangeTab(tab)}
              >
                {tab}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default AdminNavbar;
