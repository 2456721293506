import { useState } from "react";
import "./AdminCourtModal.scss";

import { useFormInput } from "../../utils/Common";
import { changeCourtInfo, createCourt } from "../../services/api";
import { removeTimeSeconds } from "../../utils/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function AdminCourtModal(props) {
  const isEdit = props.court.id;
  // error states
  const [infoError, setInfoError] = useState(null);

  // info inputs
  let name = props.court.name;
  let startTime = removeTimeSeconds(props.court.startTime);
  let endTime = removeTimeSeconds(props.court.endTime);
  const newName = useFormInput(name || "");
  const newStartTime = useFormInput(startTime || "");
  const newEndTime = useFormInput(endTime || "");
  const [isIndoor, setIsIndoor] = useState(props.court.isIndoor || false);

  function handleChangeIsIndoor(e) {
    setIsIndoor(e.target.checked);
  }

  function onCreateCourt() {
    // check if time format is correct
    if (validateTime(newStartTime.value) && validateTime(newEndTime.value)) {
      // set new court info
      const newCourt = {
        name: newName.value,
        isIndoor: isIndoor,
        startTime: newStartTime.value,
        endTime: newEndTime.value,
      };

      // try to create court in db
      createCourt(newCourt)
        .then(() => {
          setInfoError(null);
          props.onNotify("Campo creato correttamente.");
          props.onClose();
        })
        .catch((error) => {
          setInfoError(error.response.data.message);
        });
    } else {
      setInfoError(
        "Il formato dell'ora deve essere 00:00 e maggiore dell'orario d'inizio"
      );
    }
  }

  function onChangeCourtInfo() {
    // check if time format is correct
    if (validateTime(newStartTime.value) && validateTime(newEndTime.value)) {
      // set new court info
      const courtInfo = {
        name: newName.value,
        isIndoor: isIndoor,
        startTime: newStartTime.value,
        endTime: newEndTime.value,
      };

      // try to change court info on db
      changeCourtInfo(props.court.id, courtInfo)
        .then(() => {
          setInfoError(null);
          props.onNotify("Informazioni campo modificate correttamente.");
          props.onClose();
        })
        .catch((error) => {
          setInfoError(error.message);
        });
    } else {
      setInfoError("Il formato dell'ora deve essere 00:00");
    }
  }

  function validateTime(time) {
    var splitTime = time.split(":");
    return (
      splitTime.length === 2 &&
      parseInt(splitTime[0], 10) >= 0 &&
      parseInt(splitTime[0], 10) <= 23 &&
      parseInt(splitTime[1], 10) >= 0 &&
      parseInt(splitTime[1], 10) <= 59
    );
  }

  return (
    <div className="court-modal-container">
      <div className="row justify-content-center align-items-center h-100">
        <div className="card p-4">
          <div className="text-right">
            <FontAwesomeIcon
              icon={faTimes}
              className="close-icon"
              onClick={props.onClose}
            />
          </div>
          <h3>{isEdit ? "Modifica campo" : "Crea campo"}</h3>
          <div className="row">
            <div className="col-12 col-md">
              <span>Nome:</span>
              <input
                className="form-control"
                type="text"
                {...newName}
                autoComplete="new-name"
                placeholder="Nome"
              />
            </div>
            <div className="col-12 col-md mt-2 mt-md-0">
              <label>
                <input
                  type="checkbox"
                  id="isIndoor"
                  value={true}
                  checked={isIndoor}
                  onChange={handleChangeIsIndoor}
                />
                Campo coperto
              </label>
            </div>
          </div>
          <div className="row mt-0 mt-md-4">
            <div className="col-12 col-md">
              <span>Orario di inizio:</span>
              <input
                className="form-control"
                type="text"
                {...newStartTime}
                autoComplete="new-startTime"
                placeholder="00:00"
              />
            </div>
            <div className="col-12 col-md mt-2 mt-md-0">
              <span>Orario di fine:</span>
              <input
                className="form-control"
                type="text"
                {...newEndTime}
                autoComplete="new-endTime"
                placeholder="00:00"
              />
            </div>
          </div>
          {infoError && (
            <div className="mt-2 d-flex justify-content-center">
              <small style={{ color: "red" }}>{infoError}</small>
            </div>
          )}
          <button
            className="submit-button mt-4"
            type="button"
            name="submit"
            onClick={isEdit ? onChangeCourtInfo : onCreateCourt}
          >
            Salva
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminCourtModal;
