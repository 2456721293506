import { useState } from "react";
import "./Profile.scss";

import { getUser, useFormInput, successNotification } from "../../utils/Common";
import { changeUserInfo, resetSelfPassword } from "../../services/api";
import { Toaster } from "react-hot-toast";

function Profile(props) {
  // get user info
  const user = getUser();

  // error states
  const [infoError, setInfoError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  // info inputs
  let firstName = user.firstName;
  let lastName = user.lastName;
  let rank = user.rank;
  let nickname = user.nickname;
  const newFirstName = useFormInput(firstName);
  const newLastName = useFormInput(lastName);
  const newNickname = useFormInput(nickname);
  const newRank = useFormInput(rank);

  // password inputs
  const oldPassword = useFormInput("");
  const newPassword = useFormInput("");
  const repeatPassword = useFormInput("");

  function onChangeUserInfo() {
    // set new user info
    const userInfo = {
      firstName: newFirstName.value,
      lastName: newLastName.value,
      nickname: newNickname.value,
      playerId: user.playerId,
      isAgonistic: user.isAgonistic,
      isEnabled: true,
      rank: user.isAgonistic ? newRank.value : null,
    };

    // try to change user info on db
    changeUserInfo(user.id, userInfo)
      .then((courts) => {
        setInfoError(null);
        successNotification("Informazioni modificate correttamente.");
      })
      .catch((error) => {
        setInfoError(error.response.data.message);
      });
  }

  function onResetPassword() {
    if (newPassword.value !== repeatPassword.value) {
      setPasswordError("La nuova password non coincide con la ripetizione");
      return;
    }

    // set password params
    let passwordParams = {
      nickname: user.nickname,
      oldPassword: oldPassword.value,
      newPassword: newPassword.value,
    };

    // try to change user passwird on db
    resetSelfPassword(user.id, passwordParams)
      .then(() => {
        setPasswordError(null);
        successNotification("Password modificata correttamente.");
        oldPassword.onReset();
        newPassword.onReset();
        repeatPassword.onReset();
      })
      .catch((error) => {
        console.log(error);
        setPasswordError(error.response.data.message);
      });
  }

  return (
    <div className="container">
      <h1 className="mt-2">Informazioni utente</h1>
      <div className="card p-4 mt-4">
        <h3>Modifica informazioni personali</h3>
        <div className="row">
          <div className="col-12 col-md">
            <span>Nome:</span>
            <input
              className="form-control"
              type="text"
              {...newFirstName}
              autoComplete="new-name"
              placeholder="Nome"
            />
          </div>
          <div className="col-12 col-md mt-2 mt-md-0">
            <span>Cognome:</span>
            <input
              className="form-control"
              type="text"
              {...newLastName}
              autoComplete="new-name"
              placeholder="Cognome"
            />
          </div>
          <div className="col-12 col-md mt-2 mt-md-0">
            <span>Nickname:</span>
            <input
              className="form-control"
              type="text"
              {...newNickname}
              autoComplete="new-nickname"
              placeholder="Nickname"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 mt-4">
            Atleta agonista: {user.isAgonistic ? "Si" : "No"}
          </div>
          {!!user.isAgonistic && (
            <div className="col-12 col-md-4 mt-4">
              <span>Classifica:</span>
              <input
                className="form-control"
                type="text"
                {...newRank}
                autoComplete="new-rank"
                placeholder="Classifica"
              />
            </div>
          )}
        </div>
        {infoError && (
          <div className="mt-2 d-flex justify-content-center">
            <small style={{ color: "red" }}>{infoError}</small>
          </div>
        )}
        <button
          className="submit-button mt-4"
          type="button"
          name="submit"
          onClick={onChangeUserInfo}
        >
          Salva
        </button>
      </div>
      <div className="card p-4 my-4">
        <h3>Modifica password</h3>
        <div className="row">
          <div className="col-12 col-md">
            <span>vecchia password:</span>
            <input
              className="form-control"
              type="password"
              {...oldPassword}
              autoComplete="new-password"
              placeholder="Vecchia password"
            />
          </div>
          <div className="col-12 col-md mt-2 mt-md-0">
            <span>nuova password:</span>
            <input
              className="form-control"
              type="password"
              {...newPassword}
              autoComplete="new-password"
              placeholder="Nuova password"
            />
          </div>
          <div className="col-12 col-md mt-2 mt-md-0">
            <span>ripeti password:</span>
            <input
              className="form-control"
              type="password"
              {...repeatPassword}
              autoComplete="new-password"
              placeholder="Ripeti password"
            />
          </div>
        </div>
        {passwordError && (
          <div className="mt-2 d-flex justify-content-center">
            <small style={{ color: "red" }}>{passwordError}</small>
          </div>
        )}
        <button
          className="submit-button mt-4"
          type="button"
          name="submit"
          onClick={onResetPassword}
        >
          Salva
        </button>
      </div>
      <Toaster />
    </div>
  );
}

export default Profile;
