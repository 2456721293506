import "./ConfirmModal.scss";

function ConfirmModal(props) {
  return (
    <div className="confirm-modal-container">
      <div className="row justify-content-center align-items-center h-100">
        <div className="card p-4">
          <div className="row justify-content-center">
            <h3>{props.title}</h3>
          </div>
          <div className="container mt-2">
            <span>{props.message}</span>
          </div>
          {props.error && (
            <div className="mt-2 d-flex justify-content-center">
              <small style={{ color: "red" }}>{props.error}</small>
            </div>
          )}
          <div className="row mt-2 justify-content-around">
            <button
              className="cancel-button"
              type="button"
              name="cancel"
              onClick={props.onClose}
            >
              Annulla
            </button>
            <button
              className="delete-button"
              type="button"
              name="submit"
              onClick={props.onSubmit}
            >
              Conferma
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;
