import "./DesktopHeader.scss";

import { NavLink } from "react-router-dom";
import { getUser, removeUserSession, isAdmin } from "../../utils/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCog } from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/logo_transp.png";

function DesktopHeader(props) {
  // get user info
  const user = getUser();

  // handle click event of logout button
  const handleLogout = () => {
    removeUserSession();
    props.history.push("/login");
  };

  return (
    <nav className="desktop-header navbar navbar-dark">
      <span className="navbar-brand d-flex align-items-center">
        <img src={logo} width="100" height="50" alt="logo" />
        <span>TC Valpellice</span>
      </span>
      <ul className="navbar-nav mr-auto flex-row">
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" to="/bookings">
            prenotazioni
          </NavLink>
        </li>
        {isAdmin() && (
          <li className="nav-item ml-4">
            <NavLink className="nav-link" activeClassName="active" to="/admin">
              admin
            </NavLink>
          </li>
        )}
      </ul>
      <ul className="navbar-nav ml-auto align-items-center flex-row">
        <li className="navbar-text">
          <span>
            <FontAwesomeIcon icon={faUser} />
          </span>
          <span className="ml-2">
            {user.lastName} {user.firstName}
          </span>
        </li>
        {/* <li className="navbar-text ml-2">
          <span className="ml-4">gettoni: <b>{user.credits}</b></span>
        </li> */}
        <li className="nav-item ml-4">
          <NavLink className="nav-link" activeClassName="active" to="/profile">
            <FontAwesomeIcon icon={faCog} />
            <span className="ml-2">impostazioni</span>
          </NavLink>
        </li>
        <li className="nav-item ml-4">
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={handleLogout}
            type="button"
          >
            <span>Logout</span>
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default DesktopHeader;
