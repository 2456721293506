import { Fragment, useState, useEffect } from "react";
import "./Slot.scss";

import { getUser, isAdmin, isCoach } from "../../../utils/Common";
import friendlyLogo from "../../../../assets/match_icon_friendly.png";
import coachLogo from "../../../../assets/match_icon_coach.png";
import socialLogo from "../../../../assets/match_icon_social.png";
import tournamentLogo from "../../../../assets/match_icon_tournament.png";

function Slot(props) {
  // get user info
  const user = getUser();

  // check if mobile to reduce px measures
  const windowWidth = useWindowWidth();
  const [isMobile, setIsMobile] = useState(undefined);

  function handleClick() {
    // open readonly modal if full or void if empty
    if (
      props.slot.isEmpty &&
      ((!props.isCourtDisabled &&
        !props.slot.isDisabled &&
        !props.isCourtNotOpen) ||
        isAdmin() ||
        isCoach())
    ) {
      props.openModal(props.slot, false);
    } else if (
      isAdmin() ||
      (isCoach() && props.slot.eventType === "coach") ||
      (!props.slot.isDisabled && isDeletable())
    ) {
      props.openModal(props.slot, true);
    }

    return;
  }

  function isDeletable() {
    // return true if admin or same player
    return (
      isAdmin() ||
      (isCoach() && props.slot.eventType === "coach") ||
      (isSamePlayer() && props.slot.eventType === "friendly")
    );
  }

  function isSamePlayer() {
    // check if is same player
    let res = false;
    props.slot.players.forEach((player) => {
      if (player.id === user.playerId) {
        res = true;
      }
    });

    return res;
  }

  function renderPlayers(player, i, eventType) {
    // select event logo
    let logo = friendlyLogo;
    switch (eventType) {
      case "tournament":
        logo = tournamentLogo;
        break;

      case "social":
        logo = socialLogo;
        break;

      case "coach":
        logo = coachLogo;
        break;

      default:
        logo = friendlyLogo;
    }
    // render player and logo
    return (
      <Fragment key={"player_" + i}>
        <span
          className="col-6 d-flex justify-content-center align-items-center"
          key={"player_" + i}
        >
          <span>{player.lastName.toUpperCase()}</span>
          {player.firstName !== "" && (
            <span className="ml-1">
              {player.firstName.toUpperCase().substring(0, 1) + "."}
            </span>
          )}
        </span>
        <span className="col-12 d-flex justify-content-center align-items-center">
          <img
            src={logo}
            width={isMobile ? "25" : "40"}
            height={isMobile ? "20" : "30"}
            alt="logo"
            className={props.slot.players.length < 3 ? "mb-auto" : ""}
          />
        </span>
      </Fragment>
    );
  }

  function getSlotHeight() {
    // split times to count
    const startH = props.slot.startTime.split(":")[0];
    const startM = props.slot.startTime.split(":")[1];
    const endH = props.slot.endTime.split(":")[0];
    const endM = props.slot.endTime.split(":")[1];

    // set base height
    var baseHeight = isMobile ? 60 : 100;
    // set slot height depending on duration
    let height =
      baseHeight *
      (endH - startH + (endM > startM ? 0.5 : endM < startM ? -0.5 : 0));
    return height + "px";
  }

  useEffect(() => {
    setIsMobile(windowWidth <= 991);
  }, [windowWidth]); // Empty array ensures that effect is only run on mount

  return (
    <div
      className={
        "row slot " +
        (props.slot.isEmpty ? "empty" : props.slot.eventType) +
        (isDeletable() ? " deletable" : "") +
        ((props.isCourtDisabled ||
          props.slot.isDisabled ||
          props.isCourtNotOpen) &&
        !isAdmin() &&
        !isCoach()
          ? " disabled"
          : "") +
        (props.isCourtDisabled || props.slot.isDisabled || props.isCourtNotOpen
          ? " gray"
          : "")
      }
      onClick={handleClick}
      style={{ height: getSlotHeight() }}
    >
      <div className="col-2 time-col text-center p-0">
        <span>{props.slot.startTime}</span>
      </div>
      <div className="col-10 row players p-0 m-0">
        {props.slot.players.length > 0 &&
          props.slot.players.map((player, i) => {
            return i === 1 || (i === 0 && props.slot.eventType === "coach") ? (
              renderPlayers(player, i, props.slot.eventType)
            ) : (
              <span
                className="col-6 d-flex justify-content-center align-items-center"
                key={"player_" + i}
              >
                <span>{player.lastName.toUpperCase()}</span>
                {player.firstName !== "" && (
                  <span className="ml-1">
                    {player.firstName.toUpperCase().charAt(0) + "."}
                  </span>
                )}
              </span>
            );
          })}
      </div>
    </div>
  );
}

// Hook
function useWindowWidth() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowWidth, setWindowWidth] = useState(undefined);
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowWidth(window.innerWidth);
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowWidth;
}

export default Slot;
