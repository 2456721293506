import { useState, useEffect } from "react";
import "./App.scss";

import { HashRouter, Switch, Route } from "react-router-dom";
import {
  getToken,
  removeUserSession,
  setUserSession,
  getSessionAcceptedCookies,
  setSessionAcceptedCookies,
} from "./utils/Common";
import { verifyToken } from "./services/api";
import PrivateRoute from "./utils/PrivateRoute";
import PublicRoute from "./utils/PublicRoute";

import Login from "./views/login/Login";
import Bookings from "./views/bookings/Bookings";
import Profile from "./views/profile/Profile";
import Admin from "./views/admin/Admin";
import Privacy from "./views/privacy/Privacy";
import CookieBanner from "./components/cookieBanner/CookieBanner";

function App(props) {
  const [authLoading, setAuthLoading] = useState(true);
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  const verify = async (token) => {
    try {
      // verify user data from token and set session user
      let { data } = await verifyToken({ token: token });
      setUserSession(token, data);
      setAuthLoading(false);
    } catch (error) {
      removeUserSession();
      setAuthLoading(false);
    }
  };

  function acceptCookies() {
    setSessionAcceptedCookies();
    setCookiesAccepted(true);
  }

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }

    setCookiesAccepted(getSessionAcceptedCookies());

    verify(token);
  }, [cookiesAccepted]);

  // load while checking token info
  if (authLoading && getToken()) {
    return (
      <div className="min-vh-100 min-vw-100 d-flex justify-content-center align-items-center spinner-container">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <HashRouter>
        <Switch>
          <PublicRoute path="/login" component={Login} />
          <PrivateRoute path="/bookings" component={Bookings} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/admin" component={Admin} />
          <Route path="/privacy" component={Privacy} />
          <PublicRoute path="*" component={Login} />
        </Switch>
        {!cookiesAccepted && <CookieBanner acceptCookies={acceptCookies} />}
      </HashRouter>
    </div>
  );
}

export default App;
