import { Route, Redirect } from "react-router-dom";
import DesktopHeader from "../components/desktopHeader/DesktopHeader";
import MobileHeader from "../components/mobileHeader/MobileHeader";
import Footer from "../components/footer/Footer";
import { getToken } from "./Common";

// handle the private routes
function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        getToken() ? (
          <div className="d-flex flex-column min-vh-100">
            <div className="d-none d-lg-block">
              <DesktopHeader {...props} />
            </div>
            <div className="d-lg-none">
              <MobileHeader {...props} />
            </div>
            <main>
              <Component {...props} />
            </main>
            <Footer />
          </div>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
