import "./CookieBanner.scss";

import { NavLink } from "react-router-dom";

function CookieBanner(props) {
  return (
    <div className="cookie-banner pt-2">
      <p className="mb-2">
        Questo sito o gli strumenti terzi da questo utilizzati si avvalgono di
        cookie necessari al funzionamento ed utili alle finalità illustrate
        nella cookie policy.
      </p>
      <div className="mb-2 row">
        <span className="col-12 col-lg-auto mb-2 mb-lg-0">
          Chiudendo questo banner o proseguendo la navigazione in altra maniera,
          acconsenti all’uso dei cookie.
        </span>
        <NavLink to="/privacy">
          <button className="btn btn-secondary ml-4 col-auto">
            Cookie Policy
          </button>
        </NavLink>
        <button
          className="btn btn-primary mx-4 col-auto"
          onClick={props.acceptCookies}
        >
          Ok
        </button>
      </div>
    </div>
  );
}

export default CookieBanner;
