import { useState, useEffect } from "react";
import "./AdminUserModal.scss";

import { useFormInput } from "../../utils/Common";
import {
  changeUserInfo,
  createUser,
  getAvailableRoles,
} from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

function AdminUserModal(props) {
  const isEdit = props.user.id;
  // error states
  const [infoError, setInfoError] = useState(null);

  // info inputs
  let firstName = props.user.firstName;
  let lastName = props.user.lastName;
  let rank = props.user.rank;
  let nickname = props.user.nickname;
  const newFirstName = useFormInput(firstName || "");
  const newLastName = useFormInput(lastName || "");
  const newNickname = useFormInput(nickname || "");
  const newRank = useFormInput(rank || "");
  const newPassword = useFormInput("");
  const [isAgonistic, setIsAgonistic] = useState(
    props.user.isAgonistic || false
  );
  const [isEnabled, setIsEnabled] = useState(props.user.isEnabled || false);
  const [roleType, setRoleType] = useState(props.user.roleId || null);
  const [rolesList, setRolesList] = useState([]);

  function onRoleChange(e) {
    setRoleType(e.value);
  }

  function handleChangeIsAgonistic(e) {
    setIsAgonistic(e.target.checked);
  }

  function handleChangeIsEnabled(e) {
    setIsEnabled(e.target.checked);
  }

  function onCreateUser() {
    // set new user info
    const newUser = {
      firstName: newFirstName.value,
      lastName: newLastName.value,
      nickname: newNickname.value,
      isEnabled: isEnabled,
      isAgonistic: isAgonistic,
      password: newPassword.value,
      rank: isAgonistic ? newRank.value : null,
      roleId: roleType,
    };

    // try to create user in db
    createUser(newUser)
      .then(() => {
        setInfoError(null);
        props.onNotify("Utente creato correttamente.");
        props.onClose();
      })
      .catch((error) => {
        setInfoError(error.response.data.message);
      });
  }

  function onChangeUserInfo() {
    // set new user info
    const userInfo = {
      firstName: newFirstName.value,
      lastName: newLastName.value,
      nickname: newNickname.value,
      isEnabled: isEnabled,
      playerId: props.user.playerId,
      isAgonistic: isAgonistic,
      rank: isAgonistic ? newRank.value : null,
      roleId: roleType,
    };

    // try to change user info on db
    changeUserInfo(props.user.id, userInfo)
      .then(() => {
        setInfoError(null);
        props.onNotify("Informazioni utente modificate correttamente.");
        props.onClose();
      })
      .catch((error) => {
        setInfoError(error.response.data.message);
      });
  }

  useEffect(() => {
    getAvailableRoles()
      .then((roles, error) => {
        let roleTypes = [];
        // check all available roles
        roles.data.forEach((role) => {
          roleTypes.push({
            value: role.id,
            label:
              role.name === "ADMIN"
                ? "Admin"
                : role.name === "COACH"
                ? "Maestro"
                : "Giocatore",
          });

          // set selected role to player if void
          if (role.name === "PLAYER" && !props.user.roleId) {
            setRoleType(role.id);
          }
        });

        // set role types options
        setRolesList(roleTypes);
      })
      .catch((error) => {
        setInfoError(error.response.data.message);
      });
  }, [props.user.roleId]);

  return (
    <div className="user-modal-container">
      <div className="row justify-content-center align-items-center h-100">
        <div className="card p-4">
          <div className="text-right">
            <FontAwesomeIcon
              icon={faTimes}
              className="close-icon"
              onClick={props.onClose}
            />
          </div>
          <h3>{isEdit ? "Modifica utente" : "Crea utente"}</h3>
          <div className="row">
            <div className="col-12 col-md">
              <span>Nome:</span>
              <input
                className="form-control"
                type="text"
                {...newFirstName}
                autoComplete="new-name"
                placeholder="Nome"
              />
            </div>
            <div className="col-12 col-md mt-2 mt-md-0">
              <span>Cognome:</span>
              <input
                className="form-control"
                type="text"
                {...newLastName}
                autoComplete="new-name"
                placeholder="Cognome"
              />
            </div>
            <div className="col-12 col-md mt-2 mt-md-0">
              <span>Nickname:</span>
              <input
                className="form-control"
                type="text"
                {...newNickname}
                autoComplete="new-nickname"
                placeholder="Nickname"
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-md-4">
              <label>
                <input
                  type="checkbox"
                  id="isEnabled"
                  value={true}
                  checked={isEnabled}
                  onChange={handleChangeIsEnabled}
                />
                Utente Abilitato
              </label>
            </div>
            <div className="col-12 col-md-4">
              <label>
                <input
                  type="checkbox"
                  id="isAgonistic"
                  value={true}
                  checked={isAgonistic}
                  onChange={handleChangeIsAgonistic}
                />
                Atleta agonista
              </label>
            </div>
            {!!isAgonistic && (
              <div className="col-12 col-md-4">
                <span>Classifica:</span>
                <input
                  className="form-control"
                  type="text"
                  {...newRank}
                  autoComplete="new-rank"
                  placeholder="Classifica"
                />
              </div>
            )}
          </div>
          <div className="row mt-2">
            {!isEdit && (
              <div className="col-12 col-md-4">
                <span>Password:</span>
                <input
                  className="form-control"
                  type="password"
                  {...newPassword}
                  autoComplete="new-password"
                  placeholder="Password"
                />
              </div>
            )}
            <div className="col-12 col-md-4">
              <span>Ruolo:</span>
              <Select
                options={rolesList}
                onChange={(event) => onRoleChange(event)}
              />
            </div>
          </div>
          {infoError && (
            <div className="mt-2 d-flex justify-content-center">
              <small style={{ color: "red" }}>{infoError}</small>
            </div>
          )}
          <button
            className="submit-button mt-4"
            type="button"
            name="submit"
            onClick={isEdit ? onChangeUserInfo : onCreateUser}
          >
            Salva
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminUserModal;
