import "./BookingNavbar.scss";

import { formatDate, getWeekday } from "../../utils/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

function BookingNavbar(props) {
  return (
    <nav className="booking-navbar d-flex justify-content-lg-start justify-content-center">
      <button className="btn btn-text" onClick={() => props.changeDay(-1)}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <span className="ml-4 d-flex align-items-center">
        <FontAwesomeIcon icon={faCalendarAlt} />
        <span className="ml-2">{getWeekday(props.currentDate)}</span>
        <span className="ml-2">{formatDate(props.currentDate, "/", "it")}</span>
      </span>
      <button className="btn btn-text ml-4" onClick={() => props.changeDay(1)}>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </nav>
  );
}

export default BookingNavbar;
