import { useState } from "react";
import "./Bookings.scss";

import BookingNavbar from "../../components/bookingsNavbar/BookingNavbar";
import BookingTable from "../../components/bookingTable/BookingTable";

function Bookings(props) {
  const [currentDate, setCurrentDate] = useState(new Date());

  // set date by navbar event
  const changeDay = (num) => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + num)));
  };

  return (
    <div className="bookings">
      <BookingNavbar currentDate={currentDate} changeDay={changeDay} />
      <BookingTable currentDate={currentDate} changeDay={changeDay} />
    </div>
  );
}

export default Bookings;
