import { useState } from "react";
import toast from "react-hot-toast";

// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// check if user is Fede to theme for her
export const isFede = () => {
  const user = getUser();

  return (
    user &&
    user.firstName.toLowerCase() === "federica" &&
    user.lastName.toLowerCase() === "manfredi"
  );
};

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem("token") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(user));
};

// return the agreement to cookies
export const getSessionAcceptedCookies = () => {
  return localStorage.getItem("cookieAccepted") || false;
};

// set the agreement to cookies
export const setSessionAcceptedCookies = () => {
  localStorage.setItem("cookieAccepted", true);
};

export const formatDate = (dateInMills, separator = "/", lng) => {
  let date = dateInMills.getDate();
  let month = dateInMills.getMonth() + 1;
  let year = dateInMills.getFullYear();

  switch (lng) {
    case "it":
      return `${date}${separator}${month < 10 ? `0${month}` : `${month}`}`;

    case "en":
      return `${year}${separator}${
        month < 10 ? `0${month}` : `${month}`
      }${separator}${date}`;

    default:
      return `${date}${separator}${
        month < 10 ? `0${month}` : `${month}`
      }${separator}${year}`;
  }
};

export const getWeekday = (dateInMills) => {
  let day = dateInMills.getDay();
  let weekday = ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"];

  return weekday[day];
};

export const isAdmin = () => {
  const user = getUser();

  return user.role === "ADMIN" || user.role === "SUPERADMIN";
};

export const isCoach = () => {
  const user = getUser();

  return user.role === "COACH";
};

export const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const reset = () => {
    setValue(initialValue);
  };
  return {
    value,
    onChange: handleChange,
    onReset: reset,
  };
};

export const removeTimeSeconds = (time) => {
  if (!time) return;

  const splitTime = time.split(":");

  return splitTime[0] + ":" + splitTime[1];
};

export const successNotification = (message) => {
  return toast.success(message, {
    // Styling
    style: { backgroundColor: "#2ecc71" },
    // Custom Icon
    // icon: '👏',
    // Change colors of success/error/loading icon
    iconTheme: {
      primary: "#fff",
      secondary: "#000",
    },
  });
};
