import { useState, useEffect, Fragment } from "react";
import "./BookingTable.scss";

import { getUser, isAdmin } from "../../utils/Common";
import {
  getBookingList,
  getCourtList,
  createBooking,
  deleteBooking,
  getPlayer,
} from "../../services/api";
import { formatDate } from "../../utils/Common";

import DesktopCourt from "./desktopCourt/DesktopCourt";
import MobileCourt from "./mobileCourt/MobileCourt";
import BookingModal from "./bookingModal/BookingModal";

function BookingTable(props) {
  // set states
  const [courts, setCourts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const [currentSlot, setCurrentSlot] = useState({});
  const [isCurrentSlotReadonly, setIsCurrentSlotReadonly] = useState(false);
  const [shownCourtIndex, setShownCourtIndex] = useState(0);

  function closeModal() {
    // close modal and set err to null
    setError(null);
    setShowModal(false);
  }

  function openModal(slot, isReadonly) {
    // open modal setting slot and if readonly
    setCurrentSlot(slot);
    setIsCurrentSlotReadonly(isReadonly);
    setShowModal(true);
  }

  async function handleBooking(params) {
    // validate players
    if (params.eventType === "friendly") {
      if (
        !params.player1 ||
        !params.player2 ||
        (params.matchType === "double" &&
          (!params.player1 ||
            !params.player2 ||
            !params.player3 ||
            !params.player4))
      ) {
        setError("Mancano uno o più giocatori");
        return;
      }
      let playersList = [
        params.player1,
        params.player2,
        params.player3,
        params.player4,
      ];
      if (
        guestNumber(playersList) > params.unsignedPlayers.length ||
        params.unsignedPlayers.filter((player) => player === "").length !== 0
      ) {
        setError("Mancano uno o più ospiti");
        return;
      } else {
        for (let i = 0; i < params.unsignedPlayers.length; i++) {
          params.unsignedPlayers[i] += " (O)";
        }
      }
    } else if (
      params.eventType === "tournament" ||
      params.eventType === "social"
    ) {
      if (
        params.unsignedPlayers[0] === "" ||
        params.unsignedPlayers[1] === "" ||
        (params.matchType === "double" &&
          (params.unsignedPlayers[0] === "" ||
            params.unsignedPlayers[1] === "" ||
            params.unsignedPlayers[2] === "" ||
            params.unsignedPlayers[3] === ""))
      ) {
        setError("Mancano uno o più giocatori");
        return;
      }
    } else if (params.eventType === "coach" && !params.player1) {
      setError("Manca il maestro");
      return;
    }

    // set base endTime
    var endTime = currentSlot.endTime;
    if (params.matchType === "double") {
      const endH = +currentSlot.endTime.split(":")[0] + 1;
      const endM = currentSlot.endTime.split(":")[1];

      endTime = (endH < 10 ? "0" + endH : endH) + ":" + endM;
    }

    //validate endTime
    if (params.eventType !== "friendly") {
      var time = params.endTime.value.split(":");
      if (
        time.length === 2 &&
        parseInt(time[0], 10) >= 0 &&
        parseInt(time[0], 10) <= 23 &&
        parseInt(time[0], 10) >
          parseInt(currentSlot.startTime.split(":")[0], 10) &&
        parseInt(time[1], 10) >= 0 &&
        parseInt(time[1], 10) <= 59
      ) {
        endTime = params.endTime.value;
      } else {
        setError(
          "Il formato dell'ora deve essere 00:00 e maggiore dell'orario d'inizio"
        );
        return;
      }
    }

    // set booking params
    const newBooking = {
      bookingParams: {
        bookingDate: formatDate(props.currentDate, "/", "en"),
        idCourt: currentSlot.idCourt,
        startTime: currentSlot.startTime,
        endTime: endTime,
        eventType: params.eventType || "",
      },
    };

    let bookingPlayerList = [];
    // set unsigned players
    for (let i = 1; i <= 4; i++) {
      if (params["player" + i] !== "ospite") {
        newBooking.bookingParams["idPlayer" + i] = params["player" + i];
        bookingPlayerList.push(params["player" + i]);
      }
    }
    newBooking.bookingParams.unsignedPlayers = params.unsignedPlayers.join();

    // remove null or undefined
    bookingPlayerList = bookingPlayerList.filter(function (el) {
      return el !== null && el !== undefined;
    });

    // check if some of the players has no credits
    let hasNoCredits = false;
    if (params.eventType === "friendly") {
      for (let i = 1; i <= 4; i++) {
        if (newBooking.bookingParams["idPlayer" + i]) {
          let player = await getPlayer(
            newBooking.bookingParams["idPlayer" + i]
          );
        }
      }
    }

    // check if some of the players has booked yet
    let hasBookedYet = false;
    if (params.eventType === "friendly" && !isAdmin()) {
      let bookingList = await getBookingList({
        currentDate: formatDate(props.currentDate, "/", "en"),
      });

      // check all players of todays bookings
      bookingList.data.bookingList.forEach((booking) => {
        let playersYetBooked = [];
        // check if booking is friendly
        if (booking.eventType === "friendly") {
          playersYetBooked.push(
            booking.p1Id,
            booking.p2Id,
            booking.p3Id,
            booking.p4Id
          );
        }
        // remove null or undefined
        playersYetBooked = playersYetBooked.filter(function (el) {
          return el !== null && el !== undefined;
        });
        // check if arrays has some in common
        if (bookingPlayerList.some((r) => playersYetBooked.includes(r))) {
          hasBookedYet = true;
        }
      });
    }

    if (!hasBookedYet) {
      // try to create booking on db
      createBooking(newBooking)
        .then((courts, error) => {
          loadData(props.currentDate).then((courts) => {
            setCourts(courts);
          });
          setError(null);
          closeModal();
        })
        .catch((error) => {
          setError(error.response.data.message);
          loadData(props.currentDate).then((courts) => {
            setCourts(courts);
          });
        });
    } else {
      setError("Uno dei giocatori ha già prenotato un'ora in questo giorno");
    }
  }

  function guestNumber(players) {
    // check how many players are guests
    let number = 0;
    for (let i = 0; i < players.length; i++) {
      number += players[i] === "ospite" ? 1 : 0;
    }
    return number;
  }

  function handleDelete() {
    //set booking params
    const bookingParams = {
      bookingParams: {
        bookingDate: formatDate(props.currentDate, "/", "en"),
        startTime: currentSlot.startTime + ":00",
        endTime: currentSlot.endTime + ":00",
        idCourt: currentSlot.idCourt,
      },
    };

    // try to delete booking from db
    deleteBooking(bookingParams)
      .then(() => {
        loadData(props.currentDate).then((courts) => {
          setCourts(courts);
        });
        setError(null);
        closeModal();
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  }

  function handleCourtDisabled(court) {
    // get user info
    const user = getUser();

    // set fake booking params
    const bookingParams = {
      bookingParams: {
        bookingDate: formatDate(props.currentDate, "/", "en"),
        idPlayer1: user.playerId,
        idPlayer2: user.playerId,
        idCourt: court.id,
        startTime: "00:00",
        endTime: "01:00",
        eventType: "disabled",
      },
    };

    if (court.isDisabled) {
      // try to delete fake booking from db
      deleteBooking(bookingParams)
        .then(() => {
          loadData(props.currentDate).then((courts) => {
            setCourts(courts);
          });
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    } else {
      // try to create fake booking on db
      createBooking(bookingParams)
        .then((courts, error) => {
          loadData(props.currentDate).then((courts) => {
            setCourts(courts);
          });
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    }
  }

  function handleShownCourt(number) {
    if (shownCourtIndex === courts.length - 1 && number === 1) {
      setShownCourtIndex(0);
    } else if (shownCourtIndex === 0 && number === -1) {
      setShownCourtIndex(courts.length - 1);
    } else {
      setShownCourtIndex(shownCourtIndex + number);
    }
  }

  useEffect(() => {
    // scroll to top when mounted
    window.scrollTo(0, 0);

    loadData(props.currentDate).then((courts, error) => {
      setCourts(courts);
    });
  }, [props.currentDate]);

  return (
    <div className="booking-table">
      <div className="container-fluid">
        <div className="row">
          {courts &&
            courts.map((court, index) => {
              return (
                <Fragment key={"court_" + index}>
                  <DesktopCourt
                    court={court}
                    openModal={openModal}
                    handleCourtDisabled={handleCourtDisabled}
                  />
                  {shownCourtIndex === index && (
                    <MobileCourt
                      court={court}
                      openModal={openModal}
                      handleCourtDisabled={handleCourtDisabled}
                      handleShownCourt={handleShownCourt}
                    />
                  )}
                </Fragment>
              );
            })}
        </div>
      </div>
      {showModal && (
        <BookingModal
          slot={currentSlot}
          onClose={closeModal}
          onBooking={handleBooking}
          onDeleteBooking={handleDelete}
          isReadonly={isCurrentSlotReadonly}
          error={error || null}
        />
      )}
    </div>
  );
}

async function loadData(currentDate) {
  try {
    // load courts and bookings
    let courtList = await getCourtList();
    let bookingList = await getBookingList({
      currentDate: formatDate(currentDate, "/", "en"),
    });

    // check if there is at least 1 court :00 and one :30
    let needToAddHalfHours = checkNotAlignedTimes(courtList.data.courtList);

    // set slots in each court
    courtList.data.courtList.forEach((court) => {
      court.slots = getSlots(
        court,
        bookingList.data.bookingList,
        currentDate,
        needToAddHalfHours
      );
    });
    return courtList.data.courtList;
  } catch (err) {
    console.log(err);
  }
}

function checkNotAlignedTimes(courtList) {
  let res = false;
  // set true if first court startTime is :00 and one of others :30 ora viceversa
  for (let i = 1; i < courtList.length; i++) {
    if (courtList[i].startTime !== courtList[0].startTime) {
      res = true;
    }
  }

  return res;
}

function getSlots(court, bookingList, currentDate, needToAddHalfHours) {
  const startH = court.startTime.split(":")[0];
  const startM = court.startTime.split(":")[1];
  const endH = court.endTime.split(":")[0];
  let slots = [];
  let lastSlotEnd;

  // if more than 16:00 you can book for day after tomorrow else tomorrow
  const actualTime = new Date();
  let maxBookingTime = actualTime.setDate(
    actualTime.getDate() + (actualTime.getHours() >= 16 ? 2 : 1)
  );
  if (
    currentDate > maxBookingTime ||
    new Date(formatDate(currentDate, "/", "en")) <
      new Date(formatDate(new Date(), "/", "en"))
  ) {
    court.isNotOpen = true;
  }

  // add starting half hour in middle hour courts
  if (startM === "30" && needToAddHalfHours) {
    slots.push({
      startTime: startH + ":00",
      endTime: startH + ":30",
      courtName: court.name,
      idCourt: court.id,
      date: currentDate,
      players: [],
      isEmpty: true,
      isDisabled: true,
    });
  }

  // loop on slots between start and end of court times
  for (let i = +startH; i <= endH; ) {
    let endTime = (i + 1 < 10 ? "0" + (i + 1) : i + 1) + ":" + startM;
    // set base slot
    var slot = {
      startTime: lastSlotEnd || (i < 10 ? "0" + i : i) + ":" + startM,
      endTime: endTime,
      courtName: court.name,
      idCourt: court.id,
      date: currentDate,
    };

    // update slot with empty or full info
    slot = checkSlotPlayers(slot, bookingList, court, currentDate);

    // set half hours endTime
    if (
      slot.endTime.split(":")[0] !== slot.startTime.split(":")[0] &&
      slot.endTime.split(":")[1] !== slot.startTime.split(":")[1] &&
      slot.isEmpty &&
      startM === "30"
    ) {
      slot.endTime =
        slot.startTime.split(":")[0] +
        ":" +
        (slot.startTime.split(":")[1] === "30" ? "00" : "30");
    }
    // check if slot is half hour or less than +1h and disable it
    if (
      (slot.isEmpty &&
        slot.endTime.split(":")[1] !== slot.startTime.split(":")[1]) ||
      isTooLate(currentDate, slot.startTime)
    ) {
      slot.isDisabled = true;
    }
    // set i to keep going with loop
    i = +slot.endTime.split(":")[0];
    // set last endTime needed to next startTime
    lastSlotEnd = slot.endTime;
    slots.push(slot);
  }

  // add ending half hour in plain hour courts
  if (startM === "00" && needToAddHalfHours) {
    slots.push({
      startTime: lastSlotEnd,
      endTime: lastSlotEnd.split(":")[0] + ":30",
      courtName: court.name,
      idCourt: court.id,
      date: currentDate,
      players: [],
      isEmpty: true,
      isDisabled: true,
    });
  }
  return slots;
}

function isTooLate(currentDate, startTime) {
  const actualH = new Date().getHours();
  const starth = startTime.split(":")[0];
  // check if is less than +1H
  if (
    starth > actualH + 1 ||
    formatDate(currentDate, "/", "en") !== formatDate(new Date(), "/", "en")
  ) {
    return false;
  } else {
    return true;
  }
}

function checkSlotPlayers(slot, bookingList, court) {
  slot.players = [];
  slot.isEmpty = true;

  // check all bookings
  bookingList.forEach((booking) => {
    if (booking.idCourt === court.id && booking.eventType === "disabled") {
      court.isDisabled = true;
    } else if (
      booking.idCourt === court.id &&
      booking.startTime === slot.startTime + ":00"
    ) {
      // check if booking and slot times intersects
      slot.isEmpty = false;
      slot.eventType = booking.eventType || "friendly";

      // set endtime & remove last ":00"
      const endH = booking.endTime.split(":")[0];
      const endM = booking.endTime.split(":")[1];
      slot.endTime = endH + ":" + endM;
      let unsignedPlayers = [];

      if (booking.unsignedPlayers) {
        unsignedPlayers = booking.unsignedPlayers.split(",");
      }

      // set players to full slot
      for (let i = 1; i <= 4; i++) {
        if (booking["p" + i + "LastName"]) {
          let player = {
            id: booking["p" + i + "Id"],
            lastName: booking["p" + i + "LastName"],
            firstName: booking["p" + i + "FirstName"],
          };
          slot.players.push(player);
        }
        if (unsignedPlayers[i - 1]) {
          let player = {
            id: 0,
            lastName: unsignedPlayers[i - 1],
            firstName: "",
          };
          slot.players.push(player);
        }
      }
    }
  });
  return slot;
}

export default BookingTable;
