import "./Footer.scss";

import { NavLink } from "react-router-dom";
import logo from "../../../assets/logo_transp.png";

function Footer(props) {
  return (
    <footer className="footer mt-auto">
      <nav className="navbar navbar-dark container">
        <span className="navbar-brand mx-auto mx-md-0 col-12 col-lg-auto text-center text-lg-left">
          <img src={logo} width="100" height="50" alt="logo" />
        </span>
        <div className="navbar-brand mx-auto col-12 col-lg-auto">
          <div className="row">
            <span className="info col-12 col-lg text-center text-lg-left">
              ASD NUOVA POLISPORTIVA VALPELLICE
            </span>
            <span className="info col-12 col-lg text-center">
              Viale Dante 25 - 1066 TORRE PELLICE
            </span>
            <span className="info col-12 col-lg text-center text-lg-right">
              Tel: 0121 932081
            </span>
          </div>
        </div>
        <ul className="navbar-nav ml-md-auto mx-auto mx-md-0 col-12 col-lg-auto pr-0">
          <li className="nav-item">
            <NavLink
              className="nav-link text-center text-lg-right "
              activeClassName="active"
              to="/privacy"
            >
              privacy policy
            </NavLink>
          </li>
        </ul>
      </nav>
    </footer>
  );
}

export default Footer;
