import { useState } from "react";
import "./AdminCourtsTable.scss";

import { removeTimeSeconds } from "../../utils/Common";
import { deleteCourt } from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import ConfirmModal from "../confirmModal/ConfirmModal";

function AdminCourtsTable(props) {
  const [deleteMessage, setDeleteMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deletingCourt, setDeletingCourt] = useState({});
  const [error, setError] = useState(null);

  function onOpenConfirmModal(court) {
    setDeleteMessage("Sicuro di voler eliminare il campo: " + court.name + "?");
    setDeletingCourt(court);
    setShowConfirmModal(true);
  }

  function onCloseModal() {
    setError(null);
    setShowConfirmModal(false);
    props.loadCourts();
  }

  function deleteSelectedCourt() {
    deleteCourt(deletingCourt.id)
      .then((res) => {
        setError(null);
        setShowConfirmModal(false);
        props.onNotify("Campo eliminato correttamente.");
        props.loadCourts();
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  }

  return (
    <div>
      <table className="table table-striped table-hover table-bordered table-responsive-sm court-table">
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Campo coperto</th>
            <th scope="col">Orario d'inizio</th>
            <th scope="col">Orario di fine</th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {props.courts.map((court, i) => {
            return (
              <tr key={"court_" + i}>
                <td>{court.name}</td>
                <td>{court.isIndoor ? "Si" : "No"}</td>
                <td>{removeTimeSeconds(court.startTime)}</td>
                <td>{removeTimeSeconds(court.endTime)}</td>
                <td>
                  <button
                    className="btn btn-text btn-sm"
                    onClick={() => props.onOpenCourtModal(court)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button
                    className="btn btn-text btn-sm"
                    onClick={() => onOpenConfirmModal(court)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {showConfirmModal && (
        <ConfirmModal
          error={error}
          title="Elimina Campo"
          message={deleteMessage}
          onClose={onCloseModal}
          onSubmit={deleteSelectedCourt}
        />
      )}
    </div>
  );
}

export default AdminCourtsTable;
