import { useEffect } from "react";
import "./Privacy.scss";

import { isFede, getToken } from "../../utils/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import DesktopHeader from "../../components/desktopHeader/DesktopHeader";
import MobileHeader from "../../components/mobileHeader/MobileHeader";

function Privacy(props) {
  // back to last page
  function goBack() {
    props.history.goBack();
  }

  // scroll to top when mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={"privacy" + (isFede() ? " fede" : "")}>
      {getToken() && (
        <>
          <div className="d-none d-lg-block">
            <DesktopHeader {...props} />
          </div>
          <div className="d-lg-none">
            <MobileHeader {...props} />
          </div>
        </>
      )}
      <button className="btn btn-text" onClick={goBack}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <span className="ml-2">Indietro</span>
      </button>
      <div className="container">
        <p className="text">
          ASD Nuova Polisportiva Valpellice, con sede legale in Torre Pellice
          (TO), Viale Dante n. 25, P. IVA 11940290015 (di seguito
          “POLISPORTIVA”), in qualità di titolare del trattamento dei dati
          personali ai sensi del d. lgs. 196/2003 e successive modifiche –
          Codice in materia di protezione dei dati personali (“Codice Privacy”)
          – e del Regolamento UE 679/2016 – Regolamento Generale sulla
          Protezione dei Dati (“RGPD”) (d’ora in avanti Codice Privacy e GDPR
          sono collettivamente indicati come “Normativa Applicabile”) riconosce
          l’importanza della protezione dei dati personali e considera la sua
          tutela uno degli obiettivi della propria attività. Prima di comunicare
          qualsiasi dato personale, POLISPORTIVA ti invita a leggere con
          attenzione la presente privacy policy (“Privacy Policy”), poiché
          contiene informazioni importanti sulla tutela dei dati personali e
          sulle misure di sicurezza adottate per garantirne la riservatezza nel
          pieno rispetto della Normativa Applicabile. La presente Privacy
          Policy, inoltre: si intende resa solo per il sito www.tcvalpellice.it
          mentre non si applica ad altri siti web eventualmente consultati
          tramite link esterni; è da intendersi quale Informativa resa ai sensi
          dell’art. 13 della Normativa Applicabile a coloro che interagiscono
          con il Sito.
        </p>
        <hr />
        <p className="text">
          La POLISPORTIVA ti informa che il trattamento dei tuoi dati personali
          sarà improntato ai principi di liceità, correttezza, trasparenza,
          limitazione delle finalità e della conservazione, minimizzazione dei
          dati, esattezza, integrità e riservatezza. I tuoi dati personali
          verranno pertanto trattati in accordo alle disposizioni legislative
          della Normativa Applicabile e degli obblighi di riservatezza ivi
          previsti.
        </p>
        <hr />
        <h5>INDICE</h5>
        <p className="text">
          Di seguito l’indice della presente Privacy Policy così che tu possa
          facilmente trovare le informazioni inerenti al trattamento dei tuoi
          dati personali.
        </p>
        <ol className="nested parent">
          <li>TITOLARE</li>
          <li>I DATI PERSONALI OGGETTO DI TRATTAMENTO</li>
          <ol className="nested">
            <li>Dati di navigazione</li>
            <li>Dati forniti volontariamente</li>
            <li>Cookie</li>
          </ol>
          <li>
            FINALITÀ, BASE GIURIDICA E NATURA OBBLIGATORIA O FACOLTATIVA DEL
            TRATTAMENTO
          </li>
          <li>DESTINATARI</li>
          <li>TRASFERIMENTI</li>
          <li>CONSERVAZIONE DEI DATI</li>
          <li>I TUOI DIRITTI</li>
          <li>MODIFICHE</li>
        </ol>
        <hr />
        <h5>1. TITOLARE E DATA PROTECTION OFFICER</h5>
        <p className="text">
          Con riferimento alla Normativa Applicabile si comunica che il Titolare
          del Trattamento è “ADS Nuova Polisportiva Valpellice”.
        </p>
        <p className="text">
          Sarà possibile contattare il Titolare del trattamento utilizzando al
          seguente recapito: info@valpellice.it
        </p>
        <hr />
        <h5>2. I DATI PERSONALI OGGETTO DI TRATTAMENTO</h5>
        <p className="text">
          Per “Dati Personali” si intende qualsiasi informazione riguardante una
          persona fisica identificata o identificabile con particolare
          riferimento a un identificativo come il nome, un numero di
          identificazione, dati relativi all’ubicazione, un identificativo
          online o a uno o più elementi caratteristici della sua identità
          fisica, fisiologica, psichica, economica, culturale o sociale.
        </p>
        <p className="text">
          Per la consultazione delle pagine pubbliche del Sito non è richiesto
          alcun conferimento di dati personali dell’Utente.
        </p>
        <p className="text">
          I Dati Personali raccolti dal Sito sono i seguenti:
        </p>
        <h5>a. Dati di navigazione</h5>
        <p className="text">
          I sistemi informatici del Sito raccolgono alcuni Dati Personali la cui
          trasmissione è implicita nell’uso dei protocolli di comunicazione di
          Internet. Si tratta di informazioni che non sono raccolte per essere
          associate a te, ma che per loro stessa natura potrebbero, attraverso
          elaborazioni ed associazioni con dati detenuti da terzi, permettere di
          identificarti. Tra questi ci sono gli indirizzi IP o i nomi a dominio
          dei dispositivi utilizzati per connetterti al Sito, gli indirizzi in
          notazione URI (Uniform Resource Identifier) delle risorse richieste,
          l’orario della richiesta, il metodo utilizzato nel sottoporre la
          richiesta al server, la dimensione del file ottenuto in risposta, il
          codice numerico indicante lo stato della risposta data dal server
          (buon fine, errore, ecc.) ed altri parametri relativi al tuo sistema
          operativo e ambiente informatico.
        </p>
        <p className="text">
          Questi dati vengono utilizzati al fine di ricavare informazioni
          statistiche anonime sull’uso del Sito e per controllarne il corretto
          funzionamento; per permettere – vista l’architettura dei sistemi
          utilizzati – la corretta erogazione delle varie funzionalità da te
          richieste, per ragioni di sicurezza e di accertamento di
          responsabilità in caso di ipotetici reati informatici ai danni del
          Sito o di terzi, in ogni caso tali dati sono conservati dal Titolare
          del sito per il periodo strettamente necessario e comunque in
          conformità alle vigenti disposizioni normative in materia.
        </p>
        <h5>b. Dati forniti volontariamente</h5>
        <p className="text">
          Per accedere ai servizi offerti dal Sito (es. prenotazione campi,
          gestione account, gestione circolo) è necessario raccogliere e
          trattare alcune informazioni degli Utenti. Attraverso il Sito hai la
          possibilità di fornire volontariamente Dati Personali come il nome ed
          il nickname per l’iscrizione ai servizi offerti. Gli utenti sono
          liberi di fornire i propri dati personali ma il loro mancato
          conferimento può comportare l’impossibilità di ottenere il servizio
          richiesto. La POLISPORTIVA tratterà questi dati nel rispetto della
          Normativa Applicabile, assumendo che siano riferiti a te o a terzi
          soggetti che ti hanno espressamente autorizzato a conferirli in base
          ad un’idonea base giuridica che legittima il trattamento dei dati in
          questione. Rispetto a tale ultima ipotesi, ti poni come autonomo
          titolare del trattamento, assumendoti tutti gli obblighi e le
          responsabilità di legge. In tal senso, conferisci sul punto la più
          ampia manleva rispetto ad ogni contestazione, pretesa, richiesta di
          risarcimento del danno da trattamento, etc. che dovesse pervenire alla
          POLISPORTIVA da terzi soggetti i cui Dati Personali siano stati
          trattati attraverso il tuo utilizzo del Sito in violazione della
          Normativa Applicabile.
        </p>
        <h5>c. Cookie</h5>
        <p className="text">
          I cookie sono stringhe di testo di piccole dimensioni che i siti
          visitati dall'utente inviano al suo terminale, dove vengono
          memorizzati per essere poi ritrasmessi agli stessi siti alla
          successiva visita del medesimo utente. Nel corso della navigazione
          l'utente può ricevere sul suo terminale anche cookie che vengono
          inviati da pagine web diverse (cfr. Provv. Garante Privacy 8 maggio
          2014, n.229). Al fine di giungere a una corretta regolamentazione di
          tali dispositivi, è necessario distinguerli in base alle finalità
          perseguite da chi li utilizza:
        </p>
        <ul>
          <li>
            <h5>Cookie tecnici</h5>
            <p className="text">
              I cookie tecnici sono quelli utilizzati al solo fine di
              "effettuare la trasmissione di una comunicazione su una rete di
              comunicazione elettronica, o nella misura strettamente necessaria
              al fornitore di un servizio della società dell'informazione
              esplicitamente richiesto dall'abbonato o dall'utente a erogare
              tale servizio" (cfr. art. 122, comma 1, del Codice e Provv. n.
              229/2014). Possono consistere in cookie di navigazione o di
              sessione, che garantiscono la normale navigazione e fruizione del
              sito web (permettendo, ad esempio, di realizzare un acquisto o
              autenticarsi per accedere ad aree riservate). Per l'installazione
              di tali cookie non è richiesto il preventivo consenso degli
              utenti. Il nostro sito utilizza cookie tecnici e di sessione.
            </p>
          </li>
          <li>
            <h5>Cookie analitici</h5>
            <p className="text">
              Servono a monitorare l’utilizzo della pagina web da parte degli
              utenti fornendo report statistici che possono supportare l’editore
              del sito nella sua ottimizzazione. Uno degli strumenti
              maggiormente utilizzati per le analisi web è “Google Analytics”,
              un servizio di Google Inc. attraverso il quale è possibile
              raccogliere statistiche di navigazione. L’Autorità Garante ha
              chiarito che questi cookie sono inquadrabili tra quelli tecnici
              laddove venga anonimizzato (anche parzialmente) l’indirizzo IP e
              vengano eliminate le condivisioni di informazioni con Google. In
              caso contrario queste tipologie di cookie vengono associate a
              quelli di profilazione.
            </p>
          </li>
          <li>
            <h5>Cookie di profilazione</h5>
            <p className="text">
              I cookie di profilazione sono volti a creare profili relativi
              all'utente e vengono utilizzati al fine di inviare messaggi
              pubblicitari in linea con le preferenze manifestate nell'ambito
              della navigazione in rete. Vista la loro invasività all’utente
              viene richiesto il preventivo consenso alla loro installazione,
              così come previsto dall’art. 122 del Codice, con l’accettazione
              Per questa tipologia di cookie il consenso può essere espresso
              dall’Utente con una o più di una delle seguenti modalità:
            </p>
            <ul className="text">
              <li>
                Mediante specifiche configurazioni del browser utilizzato o dei
                relativi programmi informatici utilizzati per navigare le pagine
                che compongono il Sito.
              </li>
              <li>
                Mediante modifica delle impostazioni nell’uso dei servizi di
                terze parti Entrambe queste soluzioni potrebbero impedire
                all’utente di utilizzare o visualizzare parti del Sito.
              </li>
            </ul>
          </li>
        </ul>
        <p className="text">
          Ogni tipologia di cookie può essere di prima parte (cioè trattati e
          gestiti direttamente dal proprietario del sito web) ovvero di terze
          parti (quando installati e acquisiti da soggetti diversi). Tra i
          cookie di terze parti rientrano i cd. social plugin cioè quei
          componenti che permettono un’integrazione con i Social Media (quali,
          ad esempio, Facebook, Twitter, Google+, LinkedIn, Pinterest, Tumblr
          etc) che operano tramite il sito web, laddove questi generino dei
          cookie. La gestione delle informazioni raccolte da terze parti è
          disciplinata dalle relative informative cui si prega di fare
          riferimento.
        </p>
        <p className="text">I cookie di terze parti sono ad esempio:</p>
        <ul className="text">
          <li>
            Google Analytics. Si tratta di cookie che permettono di poter
            analizzare e migliorare il funzionamento del Sito. Per disattivare i
            cookie di Google Analytics, è possibile scaricare e installare il
            componente aggiuntivo del browser per l’opt-out fornito da Google
            Analytics. Questo componente aggiuntivo istruisce il codice
            javascript di Google Analytics (ga.js, analytics.js e dc.js) di
            impedire l’invio di informazioni a Google Analytics. Il componente
            aggiuntivo è disponibile per i browser più utilizzati.
          </li>
          <li>
            WordPress. Trattasi di cookie di funzionalità forniti dalla
            piattaforma WordPress al fine di ottimizzare la visualizzazione e la
            lingua del Sito. Per disattivare i cookie di WordPress, è possibile
            fare riferimento all’informativa sulla privacy di questo fornitore.
          </li>
          <li>Google YouTube</li>
          <li>Google Maps</li>
        </ul>
        <p className="text">
          È possibile modificare in qualsiasi momento le scelte effettuate al
          primo accesso seguendo le seguenti procedure:
        </p>
        <p className="text">
          <a
            className="dark"
            href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies"
            rel="noopener noreferrer"
            target="_blank"
          >
            Microsoft Windows Explorer
          </a>
        </p>
        <p className="text">
          <a
            className="dark"
            href="https://support.google.com/chrome/answer/95647?hl=en&amp;p=cpn_cookies"
            rel="noopener noreferrer"
            target="_blank"
          >
            Google Chrome
          </a>
        </p>
        <p className="text">
          <a
            className="dark"
            href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie?redirectlocale=en-US&redirectslug=Enabling+and+disabling+cookies"
            rel="noopener noreferrer"
            target="_blank"
          >
            Mozilla Firefox
          </a>
        </p>
        <p className="text">
          <a
            className="dark"
            href="https://support.apple.com/kb/PH19214?viewlocale=it_IT&locale=en_US"
            rel="noopener noreferrer"
            target="_blank"
          >
            Apple Safari
          </a>
        </p>
        <p className="text">
          I link sopra indicati possono essere soggetti a variazioni del tempo
          non dovuti al sito. Le stesse informazioni sono, comunque, facilmente
          reperibili utilizzando un motore di ricerca. Se il vostro browser non
          è presente, fate riferimento alle informazioni sui cookie fornite dal
          browser stesso. Se utilizzate un telefono cellulare, consultate il
          relativo manuale per reperire ulteriori informazioni. A seguito di
          disabilitazione dei cookie attraverso le impostazioni del browser, si
          ricorda la necessità di provvedere sempre attraverso le stesse alla
          eliminazione di quelli già presenti prima della disabilitazione
          stessa.
        </p>
        <hr />
        <h5>3. FINALITA’</h5>
        <p className="text">
          I Dati Personali che fornisci attraverso il Sito saranno trattati
          dalla POLISPORTIVA per le seguenti finalità:
        </p>
        <p className="text">
          a) finalità inerenti l’esecuzione dei servizi offerti dal Sito;
        </p>
        <p className="text">
          finalità di ricerche/analisi statistiche su dati aggregati o anonimi,
          senza dunque possibilità di identificare l’utente, volti a misurare il
          funzionamento del Sito, misurare il traffico e valutare usabilità e
          interesse;
        </p>
        <p className="text">
          c) finalità necessarie ad accertare, esercitare o difendere un diritto
          in sede giudiziaria o ogniqualvolta le autorità giurisdizionali
          esercitino le loro funzioni giurisdizionali.
        </p>
        <hr />
        <h5>4. MODALITA’</h5>
        <ul className="text">
          <li>
            Strumenti e logiche. In relazione alle finalità sopra richiamate, il
            trattamento avviene mediante strumenti manuali, informatici e
            telematici con logiche strettamente correlate alle finalità suddette
            e, comunque, in modo da garantire la sicurezza e la riservatezza dei
            dati stessi e con l’impegno da parte Vostra di comunicarci
            sollecitamente eventuali correzioni, modificazione ed aggiornamenti.
            Detto trattamento potrà essere effettuato per conto del Titolare del
            Trattamento per le finalità e con le modalità sopra descritte e nel
            rispetto di criteri idonei a garantire sicurezza e riservatezza, da
            società, studi, enti e collaboratori esterni nominati Responsabili e
            solamente per quanto attiene ai trattamenti da loro effettuati.
          </li>
          <li>
            Categorie particolari di dati. Nessuno dei vostri dati personali
            presso il Titolare del Trattamento sono riconducibili alla
            definizione di “Categorie particolari di dati” dell’art. 9 del
            Regolamento UE 2016/679. Nel caso ci fossero da Voi trasmessi dati
            di tale genere, in mancanza di un vostro esplicito consenso scritto
            sarà nostra cura cancellarli immediatamente.
          </li>
        </ul>
        <hr />
        <h5>5. DESTINATARI</h5>
        <p className="text">
          I tuoi Dati Personali potranno essere condivisi, per le finalità
          specificate al punto 3, con:
        </p>
        <ul className="text">
          <li>
            soggetti necessari per l’erogazione dei servizi offerti dal Sito,
            tra cui a titolo esemplificativo l’invio di e-mail e l’analisi del
            funzionamento del Sito che agiscono tipicamente in qualità di
            responsabili del trattamento della POLISPORTIVA;
          </li>
          <li>
            persone autorizzate dalla POLISPORTIVA al trattamento dei Dati
            Personali che si siano impegnate alla riservatezza o abbiano un
            adeguato obbligo legale di riservatezza;
          </li>
          <li>
            autorità giurisdizionali nell’esercizio delle loro funzioni quando
            richiesto dalla Normativa Applicabile.
          </li>
        </ul>
        <hr />
        <h5>6. TRASFERIMENTI</h5>
        <p className="text">
          Alcuni dei tuoi Dati Personali sono trasferiti a Destinatari che si
          potrebbero trovare al di fuori dello Spazio Economico Europeo. La
          POLISPORTIVA assicura che il trattamento elettronico e cartaceo dei
          tuoi Dati Personali da parte dei Destinatari avviene nel rispetto
          della Normativa Applicabile. Invero, i trasferimenti si basano
          alternativamente su una decisione di adeguatezza o sulle Standard
          Model Clauses approvate dalla Commissione Europea.
        </p>
        <hr />
        <h5>7. CONSERVAZIONE DEI DATI</h5>
        <p className="text">
          La POLISPORTIVA tratterà i tuoi Dati Personali per il tempo
          strettamente necessario a raggiungere gli scopi indicati al punto 3. A
          titolo esemplificativo, la POLISPORTIVA tratterà i Dati Personali per
          il servizio di prenotazione dei campi fino a quando non deciderai di
          disiscriverti dal servizio. Fatto salvo quanto sopra, la POLISPORTIVA
          tratterà i tuoi Dati Personali fino al tempo permesso dalla legge
          Italiana a tutela dei propri interessi (Art. 2947(1)(3) c.c.).
        </p>
        <hr />
        <h5>8. I TUOI DIRITTI</h5>
        <p className="text mb-0 pb-2">
          Nei limiti della Normativa Applicabile, hai il diritto di chiedere
          alla POLISPORTIVA, in qualunque momento, l’accesso ai tuoi Dati
          Personali, la rettifica o la cancellazione degli stessi o di opporti
          al loro trattamento, la limitazione del trattamento. Le richieste
          vanno rivolte via e-mail all’indirizzo: info@valpellice.it Ai sensi
          della Normativa Applicabile, hai in ogni caso il diritto di proporre
          reclamo all’autorità di controllo competente (Garante per la
          Protezione dei Dati Personali) qualora ritenessi che il trattamento
          dei tuoi Dati Personali sia contrario alla normativa vigente.
        </p>
      </div>
    </div>
  );
}

export default Privacy;
